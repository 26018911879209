import { Grid, Typography } from "@mui/material";
import React from "react";
import { FontFamily, FontSize, FontWeight } from "../constants/GlobalStyles";
import LinkButton from "./Button/LinkButton";

const Youtube = () => {
  return (
    <Grid
      sx={{
        minHeight: "50vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
        // textAlign: "justify",
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        Youtube
      </Typography>
      <Typography
        sx={{
          fontSize: FontSize.regular,
          fontWeight: FontWeight.light,
          fontFamily: FontFamily.primary,
        }}
      >
        I create YouTube videos focused on my hobbies and interests, including
        tech reviews, cinematography and riding. You can watch my videos and
        subscribe using the link below
      </Typography>
      <LinkButton
        link={"https://www.youtube.com/@siddharth-bhattacharjee"}
        name={"Channel link"}
      />
    </Grid>
  );
};

export default Youtube;
