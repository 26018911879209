import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from "../constants/GlobalStyles";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import YouTubeIcon from "../../src/assets/youtube.svg";
import Github from "../../src/assets/github.svg";
import Behance from "../../src/assets/behance.svg";
import Linkedin from "../../src/assets/linkedin.svg";

const Footer = () => {
  return (
    <Grid
      container
      sx={{
        minHeight: "20vh",
        color: Colors.backgroundBlack,
        backgroundColor: Colors.white,
        paddingX: { xs: 4, sm: 4, md: 6, lg: 12, xl: 24 },
        paddingY: { xs: 2, sm: 4, md: 4 },
      }}
      display={"flex"}
      flexWrap={"wrap"}
      gap={4}
    >
      <Grid
        item
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={1}
      >
        <Typography
          sx={{
            fontSize: FontSize.large,
            textTransform: "capitalize",
            fontWeight: FontWeight.regular,
            fontFamily: FontFamily.primary,
          }}
        >
          Contact me
        </Typography>
        <Grid display={"flex"} gap={1}>
          <EmailRoundedIcon />
          <a
            href="mailto:iamofficialsid@gmail.com"
            style={{ textDecoration: "none", color: Colors.backgroundBlack }}
          >
            <Typography
              sx={{
                fontSize: FontSize.regular,
                fontWeight: FontWeight.regular,
                fontFamily: FontFamily.primary,
              }}
            >
              iamofficialsid@gmail.com
            </Typography>
          </a>
        </Grid>
      </Grid>
      <Grid
        flex={1}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        borderRight={`3px solid ${Colors.yellow}`}
        sx={{
          paddingRight : { xs: 1, sm : 2 , md: 3, lg: 4 , xl : 6 }
        }}
        flexWrap={"wrap"}
      >
        <a href="https://github.com/Official-sidd/" target="_blank" rel="noopener noreferrer">
          <img src={Github} alt="github" />
        </a>
        <a href="https://www.behance.net/siddharthb" target="_blank" rel="noopener noreferrer">
          <img src={Behance} alt="behance" />
        </a>
        <a href="https://linkedin.com/in/siddharth-bhattcharjee" target="_blank" rel="noopener noreferrer">
          <img src={Linkedin} alt="linkedin" />
        </a>
        <a href="https://www.youtube.com/@siddharth-bhattacharjee" target="_blank" rel="noopener noreferrer">
          <img src={YouTubeIcon} alt="youtube" />
        </a>
      </Grid>
    </Grid>
  );
};

export default Footer;
