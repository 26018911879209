import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from "../../constants/GlobalStyles";
import { Link } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const LinkButton = ({ name, link }) => {
  return (
    <Grid
      sx={{
        width: "300px",
        height: "60px",
        // backgroundColor: Colors.yellow,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingX: 3,
        textDecoration : "none",
        border : `2px solid ${Colors.yellow}`
      }}
      component={Link}
      to={link}
      target="_blank"
    >
      <Typography
        sx={{
          fontSize: FontSize.large,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
          color: Colors.white,
        }}
      >
        {name}
      </Typography>
      <ArrowForwardIosRoundedIcon sx={{ color: Colors.white }} />
    </Grid>
  );
};

export default LinkButton;
