import { Grid, Typography } from "@mui/material";
import React from "react";
import { FontFamily, FontSize, FontWeight } from "../constants/GlobalStyles";
import LinkButton from "./Button/LinkButton";

const Projects = () => {
  return (
    <Grid
      sx={{
        minHeight: "50vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        Projects
      </Typography>
      <Typography
        sx={{
          fontSize: FontSize.regular,
          fontWeight: FontWeight.light,
          fontFamily: FontFamily.primary,
        }}
      >
        You can check out some of my personal works by following the below
        links.
      </Typography>
      <Grid display={"flex"} flexDirection={"column"} gap={1} >
        <LinkButton
          link={"https://github.com/Official-sidd/"}
          name={"Github"}
        />
        <LinkButton
          link={"https://www.behance.net/siddharthb"}
          name={"Behance"}
        />
      </Grid>
    </Grid>
  );
};

export default Projects;
