import { Button, Grid } from "@mui/material";
import React from "react";
import { Colors } from "../constants/GlobalStyles";

const Header = () => {
  const clickHandler = () => {
    window.open(
      "https://linkedin.com/in/siddharth-bhattcharjee",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        px: { xs: 2, sm: 4, lg: 8 },
        py: { xs: 2, sm: 3, lg: 4 },
        mx: { xs: 1, sm: 2, lg: 4, xl: 8 },
        borderBottom: `1px solid ${Colors.grey}`,
      }}
    >
      <Button
        onClick={clickHandler}
        variant="outlined"
        sx={{
          color: Colors.white,
          textTransform: "capitalize",
          py: 1,
          px: 2,
          borderRadius: 4,
          fontFamily: "Poppins, sans-serif",
          borderColor: Colors.yellow,
          "&:hover": {
            backgroundColor: Colors.yellow,
            color: Colors.backgroundBlack,
            borderColor: Colors.backgroundBlack,
          },
        }}
      >
        get in touch
      </Button>
    </Grid>
  );
};

export default Header;
