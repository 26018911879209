export const Colors = {
  backgroundBlack: "#000000",
  white: "#FFFFFF",
  grey: "#D9D9D9",
  yellow: "#CCFF00",
};

export const FontFamily = {
  primary: "Poppins, sans-serif",
};

export const FontSize = {
  small: "10px",
  regular: "16px",
  large: "24px",
  xlarge: "32px",
  xxlarge: "48px",
  xxxlarge: "64px",
  title: "96px",
};

export const FontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};
