import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Colors } from "../constants/GlobalStyles";
import Hero from "./Hero";
import Header from "./Header";
import ScrollHeader from "./ScrollHeader";
import About from "./About";
import Education from "./Education";
import Work from "./Work";
import Projects from "./Projects";
import Photography from "./Photography";
import Youtube from "./Youtube";
import Footer from "./Footer";
import { Transition } from "react-transition-group";

const Main = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
  };

  return (
    <Grid
      sx={{
        backgroundColor: Colors.backgroundBlack,
        color: Colors.white,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Header />
      <Hero />
      <Transition in={display} timeout={duration} unmountOnExit>
        {(state) => (
          <Grid
            sx={{ width: "100%" }}
            position={"fixed"}
            top={20}
            display={"flex"}
            justifyContent={"center"}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <ScrollHeader />
          </Grid>
        )}
      </Transition>
      <Grid sx={{ marginX: { xs: 4, sm: 8, md: 12, lg: 32, xl: 48 } }}>
        <About />
        <Education />
        <Work />
        <Projects />
        <Photography />
        <Youtube />
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Main;
