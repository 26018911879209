import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Colors, FontFamily, FontWeight } from "../constants/GlobalStyles";
import ResumePDF from "../assets/resume.pdf";

const ScrollHeader = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = ResumePDF;
    link.download = "Siddharth_Bhattacharjee_Resume.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Grid
      sx={{ background: Colors.white, paddingX: { xs: 4, lg: 8 , xl : 12 } }}
      width={"95%"}
      height={"10vh"}
      alignSelf={"center"}
      borderRadius={6}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography
        sx={{
          color: Colors.backgroundBlack,
          fontFamily: FontFamily.primary,
          fontWeight: FontWeight.medium,
          textTransform: "capitalize",
          cursor: "pointer",
        }}
        onClick={handleScrollToTop}
      >
        Siddharth bhattacharjee
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: Colors.backgroundBlack,
          textTransform: "capitalize",
          py: 1,
          px: { xs: 1 , sm : 2 , md : 4 , xl : 6},
          borderRadius: 4,
          fontFamily: "Poppins, sans-serif",
          borderColor: Colors.white,
          "&:hover": {
            backgroundColor: Colors.yellow,
            color: Colors.backgroundBlack,
            // border: `2px solid ${Colors.backgroundBlack}`,
          },
        }}
        onClick={handleDownload}
      >
        download resume
      </Button>
    </Grid>
  );
};

export default ScrollHeader;
