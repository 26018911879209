import { Grid, Typography } from "@mui/material";
import React from "react";
import { Colors, FontFamily, FontSize, FontWeight } from "../constants/GlobalStyles";

const About = () => {
  return (
    <Grid
      sx={{
        minHeight: "50vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        About
      </Typography>
      <Typography
        sx={{
          fontSize: FontSize.regular,
          fontWeight: FontWeight.light,
          fontFamily: FontFamily.primary,
        }}
      >
        A highly motivated <span style={{color : Colors.yellow}}> Frontend Web Developer </span> with 1 year of experience in
        React.js and a certification in React Native. Having skills in UI/UX
        design using Figma, and a proven ability to create responsive and
        user-friendly web applications. Seeking to leverage my skills and
        knowledge in a challenging yet rewarding position to contribute to
        innovative web development projects.
      </Typography>
    </Grid>
  );
};

export default About;
