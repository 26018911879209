import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from "../constants/GlobalStyles";

const Work = () => {
  return (
    <Grid
      sx={{
        minHeight: "60vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        Work
      </Typography>
      <Typography
        sx={{
          fontSize: FontSize.regular,
          fontWeight: FontWeight.light,
          fontFamily: FontFamily.primary,
        }}
      >
        <span style={{ color: Colors.yellow }}>
          Associate Software Engineer <br />
        </span>
          Zapcom Solutions, Bengaluru - Karnataka{" "}
        <br />
        Jan, 2023 - Present
      </Typography>
      <Typography>
        <ul
          style={{
            fontFamily: FontFamily.primary,
            fontWeight: FontWeight.light,
          }}
        >
          <li style={{ marginBottom: "10px" }}>
            Developed and maintained web applications using React.js, ensuring
            high performance and responsiveness.
          </li>
          <li style={{ marginBottom: "10px" }}>
            Collaborated with design teams to implement user-friendly interfaces
            and improve user experience.
          </li>
          <li style={{ marginBottom: "10px" }}>
            {" "}
            Utilized Figma for UI/UX design and prototyping, ensuring alignment
            with user requirements and feedback.{" "}
          </li>
          <li style={{ marginBottom: "10px" }}>
            Implemented state management solutions using Redux for more
            efficient and scalable application architecture.
          </li>
        </ul>
      </Typography>
    </Grid>
  );
};

export default Work;
