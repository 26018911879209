import { Grid, Typography } from "@mui/material";
import React from "react";
import LinkButton from "./Button/LinkButton";
import { FontFamily, FontSize, FontWeight } from "../constants/GlobalStyles";

const Photography = () => {
  return (
    <Grid
      sx={{
        minHeight: "50vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        Photography
      </Typography>
      <Typography
        sx={{
          fontSize: FontSize.regular,
          fontWeight: FontWeight.light,
          fontFamily: FontFamily.primary,
        }}
      >
        I am passionate about capturing moments through the lens and have a
        portfolio of my work available on Unsplash. You can view my work by
        following the link below
      </Typography>
      <LinkButton link={"https://unsplash.com/@i_enigma"} name={"Unsplash"} />
    </Grid>
  );
};

export default Photography;
