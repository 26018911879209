import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from "../constants/GlobalStyles";

const Education = () => {
  return (
    <Grid
      sx={{
        minHeight: "50vh",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: FontSize.xlarge,
          textTransform: "capitalize",
          fontWeight: FontWeight.regular,
          fontFamily: FontFamily.primary,
        }}
      >
        Education
      </Typography>
      <Grid
        display={"flex"}
        flexWrap={"wrap"}
        gap={4}
        container
      >
        <Grid item xs={12} sm={5.5}>
          <Typography
            sx={{
              fontSize: FontSize.regular,
              fontWeight: FontWeight.light,
              fontFamily: FontFamily.primary,
            }}
          >
            <span style={{ color: Colors.yellow }}>
              Master Of Computer Application{" "}
            </span>{" "}
            <br />
            Goa University, Panaji - Goa <br />
            June, 2020 - August, 2023
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <Typography
            sx={{
              fontSize: FontSize.regular,
              fontWeight: FontWeight.light,
              fontFamily: FontFamily.primary,
            }}
          >
            <span style={{ color: Colors.yellow }}>
              Bachelor of Science in Computer Science{" "}
            </span>{" "}
            <br />
            Goa University, Panaji - Goa <br />
            June, 2017 - August, 2019
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Education;
