import { Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
} from "../constants/GlobalStyles";
// import ProfileImage from "../assets/profile-image.png";
// import ProfileImage from "../assets/image.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Spline from "@splinetool/react-spline";

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleScrollToAbout = () => {
    window.scrollTo({
      top: isSmallScreen ? 620 : 600,
      behavior: "smooth",
    });
  };

  return (
    <Grid
      container
      display={"flex"}
      direction={isSmallScreen ? "column" : "row"}
      minHeight={isSmallScreen ? "80vh" : "70vh"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={isSmallScreen ? 2 : 0}
      sx={{
        paddingX: { xs: 2, sm: 8, lg: !isLargeScreen ? 12 : 24 },
      }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          mb: { xs: 2, md: 4, lg: 0 },
          pl: { xs: 0, md: 4, lg: 8 },
        }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={isSmallScreen ? 2 : 2}
        flex={1}
      >
        <Typography
          sx={{
            // fontSize: FontSize.xlarge,
            fontSize: isSmallScreen ? "7vw" : "4vh",
            textTransform: "capitalize",
            fontWeight: FontWeight.light,
            fontFamily: FontFamily.primary,
            color: Colors.grey,
          }}
        >
          i'm
        </Typography>
        <Grid>
          <Typography
            sx={{
              // fontSize: "12vh",
              fontSize: isSmallScreen ? "12vw" : "8vh",
              textTransform: "capitalize",
              fontWeight: FontWeight.regular,
              fontFamily: FontFamily.primary,
              lineHeight: 1,
              color: Colors.yellow,
            }}
          >
            siddharth
          </Typography>
          <Typography
            sx={{
              // fontSize: "12vh",
              fontSize: isSmallScreen ? "12vw" : "8vh",
              textTransform: "capitalize",
              fontWeight: FontWeight.regular,
              fontFamily: FontFamily.primary,
              lineHeight: 1,
            }}
          >
            bhattacharjee
          </Typography>
        </Grid>
        <Grid
          display={"flex"}
          gap={isSmallScreen ? 2 : 4}
          sx={{ color: Colors.grey }}
        >
          <Typography
            sx={{
              // fontSize: FontSize.xlarge,
              fontSize: isSmallScreen ? "7vw" : "4vh",
              textTransform: "capitalize",
              fontWeight: FontWeight.light,
              fontFamily: FontFamily.primary,
            }}
          >
            web dev
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: Colors.white,
              height: "auto",
            }}
          />
          <Typography
            sx={{
              // fontSize: FontSize.xlarge,
              fontSize: isSmallScreen ? "7vw" : "4vh",
              textTransform: "capitalize",
              fontWeight: FontWeight.light,
              fontFamily: FontFamily.primary,
            }}
          >
            UI UX
          </Typography>
        </Grid>
      </Grid>
      {!isLargeScreen && (
        <Grid
          lg={6}
          sx={{
            width: "500px",
            height: "500px",
            overflow: "hidden",
            pointerEvents: "none",
          }}
        >
          <Spline scene="https://prod.spline.design/BudlkxrSimnxlzQQ/scene.splinecode" />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Button
          variant="text"
          sx={{
            fontSize: FontSize.regular,
            textTransform: "lowercase",
            fontWeight: FontWeight.light,
            fontFamily: FontFamily.primary,
            color: Colors.grey,
          }}
          onClick={handleScrollToAbout}
        >
          more
          <KeyboardArrowDownRoundedIcon sx={{ color: Colors.yellow }} />
        </Button>
      </Grid>
      {/* <Grid
        item
        xs={12}
        md={6}
        flex={1}
        height={"100%"}
        minWidth={isSmallScreen ? "80vw" : "40vw"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        border={"2px solid yellow"}
      >
        <Box
          component="img"
          src={ProfileImage}
          alt="siddharth"
          sx={{
            maxWidth: "100%",
            maxHeight: "80%",
            height: "auto",
            width: isSmallScreen ? "80vw" : "auto",
            // width: "100%",
            objectFit: "contain",
            borderRadius: "5%",
          }}
        />
      </Grid> */}
    </Grid>
  );
};

export default Hero;
